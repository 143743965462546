import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
//import pic04 from '../assets/images/archetypes/superwide.jpg'
import pic04 from '../assets/images/archetypes/MJ/new/archetypes.webp'
import Graph from '../components/graph'
import book from '../assets/images/999_big.webp'

import Bitbucket from 'bitbucket'

import { createMedia } from "@artsy/fresnel"
import ImgMediaCard from '../components/card'
import ImgMediaCard2 from '../components/taotimecard'

import MoreA from '../components/morea'


const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    sm: 0,
    md: 768,
    lg: 1024,
    xl: 1192,
  },
})



class Result extends React.Component {




  constructor(props) {

    super(props);
    console.log(props);


    this.state = this.props.location.state;
    this.arch = "bookpage";


  }


  componentWillMount() {
    console.log("result page");
    console.log(this.state);
  }



  render() {
    let name = "The Inner World of Archetypes";
    return (
      <Layout>
        <Helmet title={name} />
        <HeaderGeneric text={name} subtext="A Guide to Integrating the 12 Jungian Archetypes"/>
        <div id="main">
          <section id="content" className="main">
            <span className="image main"><img src={pic04} alt="" /></span>



            <h2>Transform yourself with <a href={"https://jilecek.itch.io/the-inner-world-of-archetypes?utm_source=archetypes&utm_campaign=" + this.arch} onClick="window.fbq('track', 'AddToWishlist')">The Inner World of Archetypes</a> eBook</h2>



           
                <div>


                  

                <div className="video">
                <a href={"https://jilecek.itch.io/the-inner-world-of-archetypes?utm_source=archetypes&utm_campaign=" + this.arch} onClick="window.fbq('track', 'AddToWishlist')">
                <img src={book} width="100%" alt="" />
                </a>
                </div>
                <br/>

                <center>  <MoreA arch="bookpage"/></center>

              

                </div>
               




          </section>
        </div>
      </Layout>
    )
  }
}

export default Result
